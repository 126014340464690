import addressBookSchema from "./address-book.json";
import addressSchema from "./address.json";
import cartSchema from "./shopping_cart.json";
import checkoutSignInSchema from "./checkout-sign-in-register.json";
import collectionsSchema from "./collections.json";
import confirmationSchema from "./confirmation.json";
import createRegistrySchema from "./create-a-registry.json";
import deleteGiftListSchema from "./delete-gift-list.json";
import FAQSchema from "./frequently-asked-questions.json";
import galleryDetailsSchema from "./gallery-details.json";
import giftListDetailsSchema from "./gift-list-details.json";
import giftListEditSchema from "./gift-list-edit-details.json";
import giftListSchema from "./gift-list.json";
import giftListNotesSchema from "./manage-gift-list-thank-you-notes.json";
import manageGiftListSchema from "./manage-gift-list.json";
import manageGiftListsSchema from "./manage-gift-lists.json";
import memberCancelSchema from "./membership-cancel.json";
import memberPaymentEditSchema from "./membership-payment-edit.json";
import memberSchema from "./membership.json";
import myAccountMessagingSchema from "./my-account-messaging.json";
import orderHistoryDetailSchema from "./order-history-detail.json";
import orderHistorySchema from "./order-history.json";
import orderReviewSchema from "./order_review.json";
import paymentInfoSchema from "./payment-info.json";
import paymentSchema from "./payment.json";
import productSchema from "./product.json";
import productsSchema from "./products.json";
import profileSchema from "./profile.json";
import resultsSchema from "./results.json";
import roomsSchema from "./rooms.json";
import searchSchema from "./search.json";
import sendEmailSchema from "./send-announcement-email.json";
import updatePaymentSchema from "./update-payment.json";
import wishListSchema from "./wish-list.json";
import selfSchedulerSchema from "./self-scheduler.json";
import thankYouSchema from "./thank-you.json";
import expiredOrderSchema from "./expired-order.json";
import swatchOrderSchema from "./order.json";
import pageNotFoundSchema from "resources/page-not-found.json";

/**
 * we need to provide keys with - instead of _
 * example: shopping_cart => shopping-cart
 */
export const routeToResourceMap = {
  "address-book": addressBookSchema,
  address: addressSchema,
  shopping_cart: cartSchema,
  "checkout-sign-in-register": checkoutSignInSchema,
  collections: collectionsSchema,
  confirmation: confirmationSchema,
  "create-a-registry": createRegistrySchema,
  "delete-gift-list": deleteGiftListSchema,
  "frequently-asked-questions": FAQSchema,
  "gallery-details": galleryDetailsSchema,
  "gift-list-details": giftListDetailsSchema,
  "gift-list-edit-details": giftListEditSchema,
  "gift-list": giftListSchema,
  home: myAccountMessagingSchema,
  "manage-gift-list-thank-you-notes": giftListNotesSchema,
  "manage-gift-list": manageGiftListSchema,
  "manage-gift-lists": manageGiftListsSchema,
  "membership-cancel": memberCancelSchema,
  "membership-payment-edit": memberPaymentEditSchema,
  membership: memberSchema,
  "order-history-detail": orderHistoryDetailSchema,
  "order-history": orderHistorySchema,
  order_review: orderReviewSchema,
  "payment-info": paymentInfoSchema,
  payment: paymentSchema,
  product: productSchema,
  products: productsSchema,
  profile: profileSchema,
  results: resultsSchema,
  rooms: roomsSchema,
  search: searchSchema,
  "send-announcement-email": sendEmailSchema,
  "update-payment": updatePaymentSchema,
  "wish-list": wishListSchema,
  "self-scheduler": selfSchedulerSchema,
  "thank-you": thankYouSchema,
  "expired-order": expiredOrderSchema,
  order: swatchOrderSchema,
  "product-not-found": pageNotFoundSchema,
  "category-not-found": pageNotFoundSchema
};
